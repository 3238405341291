<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" />

	<app-content-body v-if="!is.loading">

		<app-content-summary>

			<app-content-summary-item label="Ref." :grow="true" :value="item.reference" />
			<app-content-summary-item label="Status" :tag="$constants.registration.sale.status[item.status]" :status="item.status" />
			<app-content-summary-item label="Date" :value="item.date.made | localDate('MM/DD/YYYY h:mma')" />
			<app-content-summary-item v-if="item.stripe.method" label="Payment method" :icon="'method-' + item.stripe.method" :value="'**** ' + item.stripe.preview" />
			<app-content-summary-item v-if="item.discount.code" label="Discount Code" :value="item.discount.code" />
			<app-content-summary-item v-if="item.cost.discount" label="Discount Amount" :value="item.cost.discount | formatMoney" />
			<app-content-summary-item label="Total" :value="item.cost.total | formatMoney" />

		</app-content-summary>
		
		<app-content-box v-for="badge in badges" :key="badge.id" :not-padded="true">

			<div class="sale-badge-head">

				<div class="sale-badge-head-icon"><app-icon icon="user" /></div>
				<div class="sale-badge-head-name">{{ badge.name }}</div>
				<div class="sale-badge-head-badge" v-if="$convention.settings.badges_numbers_public">Badge #{{ badge.reference }}</div>
				<div class="sale-badge-head-badge" v-if="!$convention.settings.badges_numbers_public">Badge Ref. {{ badge.reference }}</div>

			</div>

			<div class="sale-badge-item" :data-status="item.status" v-for="item in badgeItems(badge.id)" :key="item.id">

				<div class="sale-badge-item-name"><div class="label" :data-status="item.status" v-if="item.status === 2">{{ $constants.registration.sale.status[item.status] }}</div>{{ item.name }}<small v-if="item.status !== 2">{{ refundText(item) }}</small></div>
				<div class="sale-badge-item-price">{{ item.price | formatMoney }}</div>
				<div class="sale-badge-item-actions">
					<app-icon icon="refund" v-if="item.refundable" :disabled="(is.refunding && is.refunding.id !== item.id) || item.status === 2 || !item.refundable" :loading="is.refunding.id === item.id" class="sale-badge-item-actions-item" v-tooltip="'Request refund'" v-on:click.native="onRefundClick(item)" />
				</div>

			</div>

		</app-content-box>
		
		<app-content-box :not-padded="true" v-if="otherItems.length">

			<div class="sale-badge-head">

				<div class="sale-badge-head-icon"><app-icon icon="receipt" /></div>
				<div class="sale-badge-head-name">Items</div>

			</div>

			<div class="sale-badge-item" :data-status="item.status" v-for="item in otherItems" :key="item.id">

				<div class="sale-badge-item-name"><div class="label" :data-status="item.status" v-if="item.status === 2">{{ $constants.registration.sale.status[item.status] }}</div>{{ item.name }}<small v-if="item.status !== 2">{{ refundText(item) }}</small></div>
				<div class="sale-badge-item-price">{{ item.price | formatMoney }}</div>
				<div class="sale-badge-item-actions">
					<app-icon icon="refund" v-if="item.refundable" :disabled="(is.refunding && is.refunding.id !== item.id) || item.status === 2 || !item.refundable" :loading="is.refunding.id === item.id" class="sale-badge-item-actions-item" v-tooltip="'Request refund'" v-on:click.native="onRefundClick(item)" />
				</div>

			</div>

		</app-content-box>

	</app-content-body>

	<com-refund v-if="is.refunding" :item="is.refunding" v-on:close="is.refunding = false" v-on:refunded="load" />

</app-content>

</template>

<script>

import comRefund from './conventionregistrationpurchase/Refund'
import Vue from 'vue'

export default {

	components: {
		comRefund
	},

	data: function() {

		return {
			is: {
				loading: true,
				refunding: false
			},
			item: {}
		}

	},

	computed: {

		title: function() {

			return 'Purchase #' + this.item.reference

		},

		otherItems: function() {

			return this.$_.filter(this.item.items, function(item) {

				return item.type === this.$constants.registration.item.type.other || item.badge === false

			}.bind(this))

		},

		badges: function() {

			var badges = []

			this.$_.each(this.item.items, function(item) {

				if (item.badge) {

					if(!this.$_.contains(this.$_.pluck(badges, 'id'), item.badge.id)) badges.push(item.badge)

				}

			}.bind(this))

			return badges

		}

	},

	created: function() {

		this.load()

	},

	methods: {

		refundText: function(item) {

			if (item.refundable) {

				if (item.refundableDate) {

					return 'Refundable until ' + this.$options.filters.localDate(item.refundableDate, 'MM/DD/YYYY')

				} else {

					return 'Refundable'

				}

			} else {

				return 'Non-refundable'

			}

		},

		load: function() {

			this.$api.get(['convention/registration/purchase', this.$route.params.id]).then(function(json) {

				Vue.set(this, 'item', json.item)

				this.is.loading = false

			}.bind(this))

		},

		badgeItems: function(badge) {

			return this.$_.filter(this.item.items, function(item) {

				return item.badge.id === badge

			})

		},

		onRefundClick: function(item) {

			this.is.refunding = item

		}

	}

}

</script>

<style scoped>

.label {
	color: #fff;
	height: 20px;
	font-size: 12px;
	line-height: 20px;
	padding: 0px 4px;
	border-radius: 4px;
}

.sale-summary-item-value >>> .icon {
	font-size: 20px;
	margin-right: 10px;
	color: #4082d3;
}

.sale-badge-head {
	padding: 10px 10px;
	display: flex;
	border-bottom: 1px solid #ddd;
	align-items: center;
}

.sale-badge-head-icon {
	width: 24px;
	height: 24px;
	background-color: #4082d3;
	color: #fff;
	font-size: 12px;
	text-align: center;
	line-height: 26px;
	border-radius: 50%;
	margin-right: 10px;
}

.sale-badge-head-name {
	font-size: 16px;
	color: #343434;
	flex-grow: 1;
}

.sale-badge-head-badge {
	font-size: 16px;
	color: #343434;
}

.sale-badge-head-badge {
	font-size: 14px;
	display: block;
	color: #666;
}

.sale-badge-item {
	display: flex;
	line-height: 24px;
	padding: 10px 10px;
	border-bottom: 1px solid #eee;
}

.sale-badge-item[data-status="2"] {
	background-color: #fff3e7;
}

.sale-badge-item:last-child {
	border-bottom-width: 0px;
}

.sale-badge-item-name {
	font-size: 14px;
	line-height: 14px;
	flex-grow: 1;
	color: #343434;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.sale-badge-item[data-status="2"] .sale-badge-item-name {
	flex-direction: row;
	align-items: center;
}

.sale-badge-item-name small {
	font-size: 12px;
	line-height: 12px;
	margin-top: 4px;
	opacity: 0.3;
	display: block;
}

.sale-badge-item-name .label {
	margin-right: 10px;
}

.sale-badge-item-name .label[data-status="2"] {
	background-color: #ffbe7a;
}

.sale-badge-item-price {
	font-size: 14px;
	flex-shrink: 0;
	width: 200px;
	text-align: right;
}

.sale-badge-item-actions {
	margin-left: 20px;
	width: 24px;
	display: flex;
}

.sale-badge-item-actions-item {
	height: 24px;
	width: 24px;
	cursor: pointer;
	font-size: 12px;
	border-radius: 4px;
	line-height: 24px;
	background-color: #4082d3;
	text-align: center;
	color: #fff;
}

.sale-badge-item-actions-item:hover {
	background-color: #236dc9;
}

</style>